// window.bootstrap = require('./bootstrap');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import $ from 'jquery';
window.$ = window.jQuery = $;
require('jquery-ui-bundle');


import flatpickr from 'flatpickr';
window.flatpickr = flatpickr;

import Swal from 'sweetalert2';
window.Swal = Swal;


require('./custom');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

