
function mobileOffCanvasOn () {
    $("#mobileOrderOffCanvas")
        .addClass("offcanvas")
        .addClass("offcanvas-bottom")
        .removeClass("col-lg-5")
        .attr('tabindex', '-1');
}

function mobileOffCanvasOff () {
    $("#mobileOrderOffCanvas")
        .removeClass("offcanvas")
        .removeClass("offcanvas-bottom")
        .addClass("col-lg-5")
        .removeAttr('tabindex style aria-hidden');
}


$(function () {
    // Mobile order Off Canvas
    /* if ($(window).width() < 992) {
        mobileOffCanvasOn();
    } else {
        mobileOffCanvasOff();
    }

    $(window).resize(function () {
        if ($(window).width() < 992) {
            mobileOffCanvasOn();
        } else {
            mobileOffCanvasOff();
        }
    }); */

    // Shopping flow
    $('#durationDays').change(function () {
        $('#order-step-1').hide();
        $('#order-step-2').slideDown();
        $('#order-step-3').slideDown();
    });

    // Ked bude mať košík produkty, tak spustiť toto;
    // $('#order-step-3').slideDown();


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

});


